<fdx-form-section header="Timeout">
    <form [formGroup]="form">
        <div class="row mt-3">
            <label
                [for]="idPrepend + 'timeout'"
                class="col-sm-4 offset-sm-2 col-form-label text-end">
                <span>Timeout</span>
                <fa-icon
                    [icon]="iconHelp"
                    ngbPopover="The maximum duration (in seconds) allotted to this export per run to export data. Exceeding this duration will prevent data updates that would otherwise result from this export."
                    triggers="mouseenter:mouseleave"
                    popoverTitle="Timeout">
                </fa-icon>
            </label>

            <div class="col-sm-4">
                <input
                    [id]="idPrepend + 'timeout'"
                    type="number"
                    class="form-control"
                    placeholder="{{timeoutDefault}}"
                    formControlName="timeout">
            </div>
        </div>
    </form>
</fdx-form-section>
