<div
    [formGroup]="queryForm"
    #formQuery="ngForm"
    [ngClass]="{'disabled': advancedQuery.disabled}">
    <fdx-form-field
        [formControlName]="controlName"
        [form]="formQuery">
        <fdx-input-wrapper
            [labelText]="labelText"
            [classes]="{'form-control': false}">
            <fdx-collapsible-code
                [content]="queryString"
                [readonly]="isReadOnly"
                [overflowScroll]="overflowScroll">
                <div class="hstack align-items-start">
                    <fa-icon
                        *ngIf="leadingIcon"
                        [icon]="leadingIcon"
                        class="search-icon text-secondary ms-3 me-2">
                    </fa-icon>
                    <fdx-code-input
                        *ngIf="!deactivateCodeMirror"
                        [fields]="dbFields"
                        [options]="codeMirrorOptions"
                        [formControlName]="controlName"
                        [doubleMinHeight]="doubleMinHeight"
                        [id]="id"
                        class="flex-grow-1 w-0">
                    </fdx-code-input>
                </div>
                <fa-icon
                    *ngIf="cheatSheet"
                    [icon]="helpIcon"
                    size="lg"
                    (click)="showCheatSheet()"
                    class="cheat-sheet-button"
                    role="button"></fa-icon>
            </fdx-collapsible-code>
        </fdx-input-wrapper>
    </fdx-form-field>
</div>
