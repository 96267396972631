<div
    [formGroup]="queryForm"
    *ngIf="basicQueryData; else loading">
    <fdx-advanced-query
        [queryForm]="advancedQueryForm"
        [controlName]="advancedQueryControlName"
        [(queryString)]="queryString"
        (queryStringChange)="queryStringChanged()"
        [disabled]="disabled"
        [dbFields]="dbFields"
        [codeMirrorOptions]="codeMirrorOptions"
        [doubleMinHeight]="advancedDoubleMinHeight"
        [cheatSheet]="advancedQueryCheatSheet"
        [leadingIcon]="advancedLeadingIcon"
        *ngIf="!usingBasicMode">
        <!-- Doesn't play nicely while hidden -->
    </fdx-advanced-query>

    <fdx-basic-query
        [queryForm]="basicQueryForm"
        [queryString]="queryString"
        [disabled]="disabled"
        [dbFields]="dbFields"
        [basicQueryData]="basicQueryData"
        [hidden]="!usingBasicMode">
        <!-- Need reference to component even when not shown -->
    </fdx-basic-query>
</div>
<ng-template #loading>
    <p class="placeholder-glow">
        <span class="placeholder col-12 rounded fs-3"></span>
    </p>
</ng-template>
