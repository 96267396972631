import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ClassInput, normalizeClassInput } from '@feedonomics/frontend-components';
import { IconDefinition, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { Subject, debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs';

export interface SearchEnterKeyDown {
    event: KeyboardEvent;
    value: string;
}

@Component({
    selector: 'fdx-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() placeholder: string = 'Search...';
    @Input() delay: number = 0;
    @Input() useSearchButton: boolean = false;
    @Input() searchButtonString: string;
    @Input() popoverTitle: string = null;
    @Input() popoverBody: string = null;
    @Input() size?: 'sm' | 'md' | 'lg' = 'md';
    @Input() searchBarClass?: ClassInput;
    @Input() resultsFound: number = null;
    @Input() showResults: boolean = false;
    @Input() autoFocus: boolean = false;
    @Input() inputGroupLeftAddons?: TemplateRef<any> | TemplateRef<any>[];
    @Input() value: string = null;
    @Input() disabled: boolean = false;

    @Output() readonly valueChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() readonly searchButtonClick: EventEmitter<string> = new EventEmitter<string>();
    @Output() readonly enterKeyDown: EventEmitter<SearchEnterKeyDown> = new EventEmitter<SearchEnterKeyDown>();

    @ViewChild('input') public input: ElementRef<HTMLInputElement>;

    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    // eslint-disable-next-line @typescript-eslint/typedef
    public search = new FormControl<string>(
        null,
        {
            nonNullable: false
        }
    );

    public readonly searchIcon: IconDefinition = faSearch;

    get searchBarClasses(): Record<string, boolean> {
        return {
            ...normalizeClassInput(this.searchBarClass),
            [`fdx-search-bar-${this.size}`]: true,
            'flex-grow-1': this.isSearchInputGroup
        };
    }

    get searchBarInputStyles(): Record<string, any> {
        const styles: Record<string, any> = {};

        if (this.useSearchButton) {
            styles['border-top-right-radius'] = '0px';
            styles['border-bottom-right-radius'] = '0px';
            styles['border-right'] = 'unset';
        }

        if (this.inputGroupLeftAddons) {
            styles['border-top-left-radius'] = '0px';
            styles['border-bottom-left-radius'] = '0px';
            styles['border-left'] = 'unset';
        }

        return styles;
    }

    get isPopoverDisabled(): boolean {
        return !this.popoverTitle && !this.popoverBody;
    }

    get isSearchInputGroup(): boolean {
        return this.leftAddons?.length > 0 || this.useSearchButton;
    }

    get leftAddons(): TemplateRef<any>[] {
        if (this.inputGroupLeftAddons) {
            if (!Array.isArray(this.inputGroupLeftAddons)) {
                this.inputGroupLeftAddons = [this.inputGroupLeftAddons];
            }

            return this.inputGroupLeftAddons;
        }

        return null;
    }

    public ngOnInit(): void {
        if (this.disabled) {
            this.search.disable( { emitEvent: false });
        } else {
            this.search.enable({ emitEvent: false });
        }

        this.search.valueChanges
            .pipe(
                debounceTime(this.delay),
                distinctUntilChanged(),
                tap((value: string) => {
                    this.valueChange.emit(value);
                    if (!value && this.useSearchButton) {
                        // the value has been cleared via 'x' button
                        this.searchButtonClick.emit(this.search.value);
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public ngAfterViewInit(): void {
        if (this.autoFocus) {
            setTimeout(
                () => {
                    this.focusInput();
                }
            );
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ('value' in changes) {
            this.search.setValue(
                changes.value.currentValue as string | null,
                {
                    emitEvent: false
                }
            );
        }

        if ('disabled' in changes) {
            if (changes.disabled.currentValue) {
                this.search.disable( { emitEvent: false });
            } else {
                this.search.enable({ emitEvent: false });
            }
        }
    }

    public resetSearchBar(): void {
        this.search.setValue(null);
    }

    public onSearchButtonClick(): void {
        this.searchButtonClick.emit(this.search.value);
    }

    public onSearchEnterKeyDown(event$: Event): void {
        this.enterKeyDown.emit({ event: event$ as KeyboardEvent, value: this.search.value});
    }

    public focusInput(): void {
        this.input.nativeElement.focus();
    }
}
