<form
    name="existing-export"
    [formGroup]="form"
    (submit)="onSubmit($event)"
    class="row">
    <div class="col-5">
        <fdx-export-schedule-form
            [exportItem]="exportItem"
            (scheduleUpdated)="updateCurrentSchedule($event)"
            class="pb-4 border-bottom mb-4"></fdx-export-schedule-form>

        <fdx-credentials-form
            [exportItem]="exportItem"
            [showFtpUrl]="true"
            [idPrepend]="idPrepend"
            (formReady)="addCredentialsForm($event)"
            (webhookTypeChange)="webhookTypeChange.emit($event)"
            class="pb-4 border-bottom mb-4">
        </fdx-credentials-form>

        <fdx-alert
            *ngIf="editExportError"
            theme="danger"
            [body]="editExportError"></fdx-alert>

        <fdx-export-if-form
            [exportItem]="exportItem"
            [dbFields]="aggregatedDbFields"
            (formReady)="addExportIfForm($event)"
            class="pb-4 border-bottom mb-4">
        </fdx-export-if-form>

        <fdx-export-timeout-form
            [exportItem]="exportItem"
            [idPrepend]="idPrepend"
            (formReady)="addExportTimeoutForm($event)"
            class="pb-4 border-bottom mb-4"></fdx-export-timeout-form>

        <fdx-export-retries-form
            [exportItem]="exportItem"
            (formReady)="addExportRetriesForm($event)"
            class="pb-4 border-bottom mb-4"></fdx-export-retries-form>

        <fdx-export-tags-form
            [exportItem]="exportItem"
            (formReady)="addExportTagsForm($event)"
            class="pb-4 border-bottom mb-4"></fdx-export-tags-form>

        <fdx-export-options-form
            [dbSortableFields]="dbSortableFields"
            [exportItem]="exportItem"
            [exportFields]="exportItem.export_fields"
            (formReady)="addExportOptionsForm($event)"
            (exportOptionsChange)="onExportOptionsChange($event)">
        </fdx-export-options-form>
    </div>

    <div class="col-7 vstack">
        <div class="offset-1 mb-3 hstack gap-3 align-items-start justify-content-between">
            <div class="vstack">
                <div
                    ngbDropdown
                    class="d-inline-block">
                    <button
                        type="button"
                        name="export-map"
                        class="btn fs-4 fw-semibold px-0 shadow-none"
                        ngbDropdownToggle>
                        Export map
                    </button>
                    <div ngbDropdownMenu>
                        <button
                            ngbDropdownItem
                            type="button"
                            name="merge-template-string"
                            (click)="openImportTemplateStringModal()">
                            <fa-icon [icon]="iconArrowCycle"></fa-icon>
                            <span>Merge template string</span>
                        </button>
                    </div>
                </div>

                <div class="text-muted d-flex align-center">
                    <ng-container [ngSwitch]="validationStatus.status">
                        <div
                            *ngSwitchCase="'loading'"
                            class="placeholder-glow">
                            <span class="btn placeholder rounded-pill"></span>
                        </div>
                        <ng-container *ngSwitchDefault>
                            <div>
                                {{ exportFieldsTotalCount }} export fields, {{ exportFieldsMappedCount }} mapped
                            </div>
                            <ng-container *ngIf="taxonomyErrorMessage()">
                                ,
                                <fdx-icon-badge-button
                                    class="mx-1"
                                    [text]="taxonomyErrorMessage()"
                                    [theme]="taxonomyErrorStatus()"
                                    [iconOverride]="solidCircle"
                                    (buttonClick)="validationStatus.status === 'complete' ? openExportFieldsOffcanvas() : null"
                                    [disabled]="validationStatus.errors.length === 0">
                                </fdx-icon-badge-button>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <div *ngIf="shouldDisplayAddExportFieldsButton">
                <button
                    type="button"
                    name="add-export-fields"
                    class="btn btn-outline-primary"
                    (click)="openExportFieldsOffcanvas()">
                    <fa-icon [icon]="iconPlus"></fa-icon>
                    <span>Add export fields</span>
                </button>
            </div>
        </div>

        <fdx-export-fields
            [dbFields]="dbFields"
            [(fields)]="exportItem.export_fields"
            (fieldsChange)="onExportFieldsChange()"
            [deltaExport]="exportItem.delta_export"
            [existingExport]="true">
        </fdx-export-fields>
    </div>

    <div class="col-12 text-center mt-3">
        <button
            *ngIf="modified"
            [disabled]="submitDisabled"
            name="submit"
            type="submit"
            class="btn btn-primary">
            Submit Edits
        </button>
    </div>
</form>
