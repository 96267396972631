import { IFilterService, IHttpService } from 'angular';

import CSVService from '../../../../services/CSVService';
import fdxUI from '../../../../services/fdxUI';
import LogService from '../../../../services/LogService';

interface ResolverInterface {
	databaseId: number | string,
	fieldList: any[],
	transformersFilter: any
}

export default class FdxTransformersDownloadModalontroller {

	// from uibmodal
	private close: (result?: { $value }) => void;
	private dismiss: (result?: { $value: string }) => void;
	private resolve: ResolverInterface;

	public fieldList: any[] = [];
	public transformersFilter: any = {};
	public loading: boolean = false;
	public applyQueryFiltering: boolean = false;
	public applyStatusFiltering: boolean = false;
	public fieldSearch: string = '';

	private databaseId: number | string = null;

	static $inject = [
		'$filter',
		'$http',
		'CSVService',
		'fdxUI',
		'LogService'
	];

	constructor(
		private $filter: IFilterService,
		private $http: IHttpService,
		private CSVService: CSVService,
		private fdxUI: fdxUI,
		private LogService: LogService
	) { }

	$onInit() {
		this.databaseId = this.resolve.databaseId;
		this.fieldList = this.resolve.fieldList;
		this.transformersFilter = this.resolve.transformersFilter;
	}

	public selectAll(): void {
		for (const field of this.fieldList) {
			field.selected = true;
		}
	}

	public unselectAll(): void {
		for (const field of this.fieldList) {
			field.selected = false;
		}
	}

	public toggleQueryFiltering(): void {
		this.applyQueryFiltering = !this.applyQueryFiltering;
	}

	public toggleStatusFiltering(): void {
		this.applyStatusFiltering = !this.applyStatusFiltering;
	}

	public onSubmit(): void {
		this.fdxUI.startBlockUI('Downloading...');

		this.loading = true;

		const selectedColumns = this.$filter('filter')(
			this.fieldList,
			{ 'selected': true }
		);

		this.$http
			.get(`/api.php/dbs/${this.databaseId}/transformers`)
			.then(
				(response: any) => {
					const selectedTransformers = [];

					for (const field of selectedColumns) {
						selectedTransformers.push(
							this.$filter('filter')(
								response.data,
								{ 'field_name': field.name }
							)
						);
					}

					const rows = [];

					rows.push(
						[
							'field_name',
							'export_id',
							'if',
							'then',
							'enabled',
							'sort_order'
						]
					);

					for (let transformers of selectedTransformers) {
						if (this.applyQueryFiltering) {
							transformers = this.$filter('filter')(
								transformers,
								this.transformersFilter.query
							);
						}

						if (this.applyStatusFiltering) {
							transformers = this.$filter('filter')(
								transformers,
								{ 'enabled': this.transformersFilter.enabled }
							);
						}

						for (const transformer of transformers) {
							const tempExports = JSON.parse(transformer.exports);

							transformer.export_id = tempExports.export_ids;

							// fail safe for bad data. Cannot have "all" selected and others too
							if (tempExports.export_ids.includes("0")) {
								transformer.export_id = ["0"];
							}

							rows.push(
								[
									transformer.field_name,
									transformer.export_id.join(),
									transformer.selector,
									transformer.transformer,
									transformer.enabled,
									transformer.sort_order
								]
							);
						}
					}

					this.CSVService.download('transformers_selected' + '.csv', rows);

					this.close();
				},
				(error: any) => {
					this.fdxUI.showToastError('Unable to download transformers');
					this.LogService.error('FdxTransformersDownloadModalontroller', 'onSubmit', error);
				}
			)
			.finally(() => {
				this.loading = false;

				this.fdxUI.stopBlockUI();
			});
	}

	public onCancel(): void {
		this.dismiss();
	}
}
