import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BaseExportFormComponent } from '@app/exports/components/base-export-form/base-export-form.component';
import { ExistingExport } from '@app/exports/models/interfaces/existing-export.interface';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-export-timeout-form',
    styleUrls: ['export-timeout-form.component.scss'],
    templateUrl: 'export-timeout-form.component.html'
})
export class ExportTimeoutFormComponent extends BaseExportFormComponent {
    form: UntypedFormGroup;
    iconHelp = faQuestionCircle;
    timeoutDefault = 20 * 60; // 20 minutes (in seconds)

    @Input() idPrepend: string = '';

    constructor(
        private fb: UntypedFormBuilder
    ) {
        super();
    }

    protected initForm(): void {
        this.form = this.fb.group({
            timeout: [this.exportItem?.timeout ?? this.timeoutDefault]
        });
    }

    protected patchForm(exportItem: ExistingExport): void {
        this.patchValue({
            timeout: exportItem.timeout
        });
    }
}
